import Swal from 'sweetalert2';
import React from 'react';
export const CustomAlert = ({ title, paragraph1, paragraph2, onConfirm, onCancel }) => {

    React.useEffect(() => {
        const showAlert = async () => {
            // Build the HTML content
            const htmlContent = `
                <div>
                    <p style="font-size: 15px; margin: 0;marginBottom:3px; color: black;">${paragraph1}</p>
                    ${paragraph2 !== "false" ? `<p style="color: #525252; font-size: 12px">${paragraph2}</p>` : ''}
                </div>
            `;

            const result = await Swal.fire({
                title: title,
                html: htmlContent,
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
                customClass: {
                    container: 'swal2-container',
                    title: 'swal2-title',
                    confirmButton: 'add-user-button',
                    cancelButton: 'cancel-button',
                    popup: 'swal2-popup',
                    closeButton: 'cross-close-custom',
                },
                showCloseButton: true,
            });

            if (result.isConfirmed) {
                onConfirm();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                onCancel();
            } else {
                onCancel();
            }
        };

        showAlert();
    }, [title, paragraph1, paragraph2, onConfirm, onCancel]);

    return null;
};
