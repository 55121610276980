import { useEffect, useState } from "react";
import { CustomAlert } from "./CustomAlert";

const AlertHandler = () => {
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        const handleShowAlert = () => {
            setShowAlert(true);
        };

        window.addEventListener("SHOW_ALERT", handleShowAlert);

        return () => {
            window.removeEventListener("SHOW_ALERT", handleShowAlert);
        };
    }, []);

    return (
        <>
            {showAlert && (
                <CustomAlert
                    title="Schedule Updated"
                    paragraph1="The meeting schedule changed. Reload to see updates?"
                    paragraph2="false"
                    onConfirm={() => {
                        window.location.reload();
                    }}
                    onCancel={() => {
                        setShowAlert(false);
                    }}
                />
            )}
        </>
    );
};

export default AlertHandler;
