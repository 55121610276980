import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { createContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const BASE_URL = process.env.REACT_APP_API_URL;

export const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [otp, setOtp] = useState(null);
  const [email, setEmail] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [formData, setFormData] = useState({ email: '', password: '' });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
      const user = jwtDecode(token);
      setUserRole(user?.user?.role);
      getProfileData();
    }
  }, []);

  const getProfileData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/auth/get-my-profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.success === 1) {
        setProfileData(response?.data?.user);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const updateProfileData = async (updatedProfileData) => {
    setProfileData(updatedProfileData);
  };

  const login = (token) => {
    localStorage.setItem("token", token);
    setIsAuthenticated(true);
    const user = jwtDecode(token);
    setUserRole(user?.user?.role);
    getProfileData();
    setFormData({ email: '', password: '' })
  };

  const postLogoutApi = async () => {
    const token = localStorage.getItem("token");
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const data = {
      deviceSerialNumber: result.visitorId,
    }
    try {
      const response = await axios.post(`${BASE_URL}/auth/logout`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response", response)
      if (response?.data?.success === 1) {
        toast.success(response?.data?.message);
        localStorage.removeItem("token");
        setIsAuthenticated(false);
        setUserRole(null);
        setProfileData(null);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  const otphandler = (otpCode) => {
    setOtp(String(otpCode));
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userRole,
        login,
        postLogoutApi,
        otphandler,
        otp,
        setEmail,
        email,
        profileData,
        updateProfileData,
        formData,
        setFormData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
