import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import logo from "./assets/logo.png";
import { toast } from "react-toastify";

const firebaseConfig = {
    apiKey: "AIzaSyDQa7K0MqrqDu-L53cVavdnQuD5ZTWVG_o",
    authDomain: "mutual-diary-d495a.firebaseapp.com",
    projectId: "mutual-diary-d495a",
    storageBucket: "mutual-diary-d495a.firebasestorage.app",
    messagingSenderId: "174691836619",
    appId: "1:174691836619:web:cf75c402150aabcf8cb14e",
    measurementId: "G-00GC58YYH0"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/firebase-messaging-sw.js")
        .then((registration) => {
            console.log("Service Worker registered:", registration);
        })
        .catch((error) => {
            console.error("Service Worker registration failed:", error);
        });
} else {
    console.error("Service Worker not supported in this browser.");
}



const isTabActive = () => !document.hidden;
console.log("isTabActive", isTabActive())
if (isTabActive) {
    onMessage(messaging, (payload) => {

        const url = new URL(window.location.href);
        const path = url.pathname;
        const is_edit = localStorage.getItem("is_edit");
        const isEdit = JSON.parse(is_edit);

        if (Notification.permission === "granted") {
            // new Notification(payload.notification.title, {
            //     body: payload.notification.body,
            //     icon: logo,
            // });
            if (!isTabActive()) {
                return;
            }
            toast.info(
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        if (path !== '/dashboard') {
                            window.dispatchEvent(new CustomEvent("NAVIGATE"));
                        }
                    }}
                >
                    <strong>{payload.notification.title}</strong>
                    <p>{payload.notification.body}</p>
                </div>,
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    logo: logo,
                }
            );

        }
        if (payload && path === '/dashboard') {
            if (isEdit === true) {
                window.dispatchEvent(new CustomEvent("SHOW_ALERT"));
            } else {
                localStorage.setItem("is_edit", false);
                window.location.reload();
            }
        }
    });
} else {
}

export const requestForToken = async () => {
    try {
        const permission = await Notification.requestPermission();
        console.log("permission", permission);
        if (permission === "granted") {
            console.log("Fetching FCM Token...");
            const currentToken = await getToken(messaging, {
                vapidKey: "BAQm_dNmAE4tCuFpTV-B4c6EH7VAnU8R3sF6jLyuTgoNREHd3MYDUoL6Isfry3dE9WA03Vo9P3VRomc6PQM79c0",
                serviceWorkerRegistration: await navigator.serviceWorker.ready
            });

            if (currentToken) {
                console.log("FCM Token:", currentToken);
                return currentToken;
            } else {
                console.warn("FCM token not retrieved.");
            }
        } else if (permission === "denied") {
            console.error("Notification permission denied.");
            showPermissionToast(); // Custom function to show UI message
        }
    } catch (error) {
        console.error("Error fetching FCM token:", error);
    }
};
const getNotificationSettingsURL = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes("chrome")) {
        return "Go to: Settings > Privacy and security > Site settings > Notifications";
    } else if (userAgent.includes("firefox")) {
        return "Go to: Settings > Privacy & Security > Permissions > Notifications";
    } else if (userAgent.includes("safari")) {
        return "Go to: System Preferences > Notifications > Select Browser";
    } else if (userAgent.includes("edge")) {
        return "Go to: Settings > Cookies and site permissions > Notifications";
    } else {
        return "Please enable notifications from your browser settings.";
    }
};

const showPermissionToast = () => {
    const shouldAskAgain = localStorage.getItem("askedNotificationPermission");

    if (!shouldAskAgain) {
        const settingsMessage = getNotificationSettingsURL();

        toast.warning(
            <div>
                <span>You have denied notifications.</span>
                <br />
                <strong>{settingsMessage}</strong>
            </div>,
            {
                position: "top-right",
                autoClose: 7000,
            }
        );

        // localStorage.setItem("askedNotificationPermission", "true");
    }
};


export default messaging;
