import React, { useEffect, useRef, useState } from "react";
import Navbar from "../common/Navbar";
import PaintBox from "../../assets/colorPickerIcon.svg";
import LeftArrow from "../../assets/left-arrow.png";
import dayjs from "dayjs";
import RightArrow from "../../assets/right-arrow.png";

import { toast } from "react-toastify";
import axios from "axios";
import Spinner from "../common/Spinner";
import { useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import up from "../../assets/up.svg";
import down from "../../assets/down.svg";
import left from "../../assets/left.svg";
import right from "../../assets/right.svg";
// import Day1 from "./Day1";
// import Day2 from "./Day2";
// import Day3 from "./Day3";
// import Day4 from "./Day4";
// import Day5 from "./Day5";
// import Day6 from "./Day6";
// import Day7 from "./Day7";
import Day from "./Day";
const BASE_URL = process.env.REACT_APP_API_URL;

export default function Dashboard() {

  const [slotsData, setSlotsData] = useState(null);
  const [callSlotsApi, setCallSlotsApi] = useState(false)
  useEffect(() => {
    const getSlotApi = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        const response = await axios.get(`${BASE_URL}/get-slots`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSlotsData(response);
      }

    }

    getSlotApi();
    localStorage.setItem("is_edit", false);

  }, [callSlotsApi]);


  // const [statusData, SetStatusData] = useState(null);









  const navigate = useNavigate();
  const today = dayjs();
  const formattedToday = today.format("YYYY-MM-DD");
  const [value, setValue] = useState(
    dayjs(formattedToday).startOf("week").add(1, "day")
  );

  const [tableData, setTableData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [change, setChange] = useState(false);
  // const [notificationData, setNotificationData] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [statusData, SetStatusData] = useState(null);
  const [editingMeetingTextId] = useState(null);
  //removed "Clear" from below as default value
  const [statusName, setStatusName] = useState("");
  const [statusColor, setStatusColor] = useState("");
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [, setEditingMeetingText] = useState({
    time_slot: "",
    meeting_with: "",
    cancel_person: "",
    status: "",
    bg_color: "",
    meeting_note: "",
  });

  const handleSearch = (e) => {
    // console.log(e.target.value)
    setSearchText(e.target.value);
  };

  const handleColorClick = (e) => {
    // console.log(editingMeetingTextId, "id");
    const status = e.target.dataset.name;
    const bg_color = e.target.dataset.color;
    setStatusColor(e.target.dataset.color);
    setStatusName(e.target.dataset.name);
    if (editingMeetingTextId) {
      setEditingMeetingText((prevState) => ({
        ...prevState,
        status: status,
        bg_color: bg_color,
      }));
    }
  };
  const handleRightArrow = () => {
    const newValue = value.add(1, "week");
    setValue(newValue.startOf("week").add(1, "day"));
  };

  const handleLeftArrow = () => {
    const newValue = value.subtract(1, "week");
    setValue(newValue.startOf("week").add(1, "day"));
  };

  const getMeetingData = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    // console.log("HERE ARE TIME SLOTS GENERATED:", allTimeSlots);
    // Helper function to convert time slot to a sortable format

    // Helper function to sort time slots correctly

    try {
      const response = await axios.get(
        `${BASE_URL}/agent-weekly-meetings/${value.format("MM-DD-YYYY")}${searchText ? `?name=${searchText}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.success === 1) {
        setTableData(response?.data?.result);
      }
    } catch (error) {
      if (error?.response?.data) {
        if (
          error.response.data.message === "Session expired please login again"
        ) {
          // Handle session expiration
          localStorage.removeItem("token");
          navigate("/");
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data?.message);
        }
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getMeetingData();
    // eslint-disable-next-line
  }, [value, searchText, change]);
  useEffect(() => {
    getMeetingStatus();
    // eslint-disable-next-line
  }, []);
  const getMeetingStatus = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios
        .get(`${BASE_URL}/get-active-status`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .finally(() => setIsLoading(false));
      if (response?.data?.success === 1) {
        SetStatusData([
          ...response?.data?.status,
          { status_name: "Clear", status_color: "#FFFFFF" },
        ]);
        // toast.success(response?.data?.message);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        if (
          error.response.data.message === "Session expired please login again"
        ) {
          // Handle session expiration
          localStorage.removeItem("token");
          navigate("/");
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data?.message);
        }
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      // setIsLoading(false);
    }
  };

  function getWeekRange(input) {
    // console.log("THIS DATE RECEIVED TO FUNCTION:", input);

    const date = new Date(input);
    // console.log("THIS IS CONVERTED TO PROPER DATE FORMAT:", date);

    if (isNaN(date.getTime())) {
      throw new Error("Invalid date provided");
    }
    const dayOfWeek = date.getDay();
    const dayOfMonth = date.getDate();
    const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

    const monday = new Date(date);
    monday.setDate(dayOfMonth - diffToMonday);

    const sunday = new Date(monday);
    sunday.setDate(monday.getDate() + 6);

    const formatDate = (d) => {
      const options = {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
      };
      let dateStr = d.toLocaleDateString("en-US", options);

      dateStr = dateStr.replace(/(\w+), (\w+) (\d+), (\d+)/, "$1, $2 $3 $4");

      return dateStr;
    };

    const formattedMonday = formatDate(monday);
    const formattedSunday = formatDate(sunday);

    // console.log("FORMATTED MONDAY:", formattedMonday);
    // console.log("FORMATTED SUNDAY:", formattedSunday);
    // console.log("Formatted date range:", `${formattedMonday} - ${formattedSunday}`);

    return `${formattedMonday} - ${formattedSunday}`;
  }

  const handleCalendarClick = () => {
    setDatePickerOpen(true);
  };

  const handleDateChange = (newValue) => {
    if (newValue) {
      setValue(newValue.startOf("week").add(1, "day"));
      setDatePickerOpen(false);
    }
  };

  const scrollableContainerRef = useRef(null);

  const scrollRight = () => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };
  const [scrollInterval, setScrollInterval] = useState(null);
  const scrollAmount = 200; // Amount to scroll per interval
  const scrollSpeed = 200; // Interval time in milliseconds

  const startScrolling = (direction) => {
    if (scrollableContainerRef.current) {
      const interval = setInterval(() => {
        switch (direction) {
          case "left":
            scrollableContainerRef.current.scrollBy({
              left: -scrollAmount,
              behavior: "smooth",
            });
            break;
          case "right":
            scrollableContainerRef.current.scrollBy({
              left: scrollAmount,
              behavior: "smooth",
            });
            break;
          case "top":
            scrollableContainerRef.current.scrollBy({
              top: -scrollAmount,
              behavior: "smooth",
            });
            break;
          case "bottom":
            scrollableContainerRef.current.scrollBy({
              top: scrollAmount,
              behavior: "smooth",
            });
            break;
          default:
            break;
        }
      }, scrollSpeed);
      setScrollInterval(interval);
    }
  };

  const stopScrolling = () => {
    if (scrollInterval) {
      clearInterval(scrollInterval);
      setScrollInterval(null);
    }
  };
  const scrollLeft = () => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };
  const scrollTop = () => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollBy({
        top: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollBottom = () => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollBy({
        top: 200,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Navbar
        handleSearch={handleSearch}
        searchText={searchText}
        value={value}
      />
      <div className="px-2 py-4 border">
        <div className="color-picker-parent">
          <div
            className="bs-parent py-2 px-5 rounded-top"
            style={{ position: "sticky", top: "85px", zIndex: 3000 }}
          >
            <span
              className="pointer"
              onClick={handleLeftArrow}
              style={{ marginLeft: "27px" }}
            >
              <img alt="" src={LeftArrow} />
            </span>
            <small className="fw-bold">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <span style={{ marginLeft: "10px" }}>
                  {getWeekRange(value)}
                </span>
                <span
                  className="pointer"
                  onClick={handleCalendarClick}
                  style={{ marginLeft: "10px" }}
                >
                  📅
                </span>
                <span
                  style={{
                    visibility: "hidden",
                    width: "1px",
                    height: "0px",
                    display: "inline-block",
                  }}
                >
                  <DatePicker
                    open={datePickerOpen}
                    onOpen={() => setDatePickerOpen(true)}
                    onClose={() => setDatePickerOpen(false)}
                    value={value}
                    onChange={handleDateChange}
                    renderInput={(params) => <input {...params} />}
                    format=""
                  />
                </span>
              </LocalizationProvider>
            </small>
            <span className="ms-2 pointer" onClick={handleRightArrow}>
              <img alt="" src={RightArrow} />
            </span>
            <span
              className="fw-bold bs position-relative pointer"
              id="Button3"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img className="" alt="" src={PaintBox} />
            </span>
            <ul
              className="bg-white bs-drop-down dropdown-menu p-0"
              aria-labelledby="Button3"
              style={{ maxHeight: "195px", overflowY: "scroll" }}
            >
              {statusData?.length > 0 && statusData ? (
                statusData.map((st, index) => (
                  <li
                    key={st?._id || index}
                    onClick={handleColorClick}
                    data-name={st?.status_name}
                    data-color={st?.status_color}
                    style={{
                      backgroundColor: `${st?.status_color}`,
                      cursor: "pointer",
                      color: `#000000`,
                      fontWeight: "600",
                      // color: `${index === statusData?.length - 1 && "#6c757d"}`,
                    }}
                  >
                    {st?.status_name}
                  </li>
                ))
              ) : (
                <li className="text-dark">No status Found.</li>
              )}
            </ul>
          </div>
        </div>
        {isLoading && <Spinner />}

        {tableData && !isLoading && (
          <div className="table-responsive" style={{ position: "relative" }}>
            <div className="sticky-scrollbar">
              <div
                className="days-wrapper"
                ref={scrollableContainerRef}
                style={{
                  display: "flex",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  paddingBottom: "50px", // Make space for sticky buttons
                }}
              >
                <div className="days-inner">
                  <div className="day-container" style={{ minWidth: "800px" }}>
                    <Day
                      data={tableData}
                      date={value.add(1, "day")}
                      localData={value.add(0, "day")}
                      setChange={setChange}
                      change={change}
                      statusColor={statusColor}
                      statusName={statusName}
                      setStatusName={setStatusName}
                      setStatusColor={setStatusColor}
                      dayNumber={"day1"}
                      divColor={"#f3e5f5"}
                      slotsData={slotsData}
                      callSlotsApi={callSlotsApi}
                      setCallSlotsApi={setCallSlotsApi}
                      statusData={statusData}
                    />
                  </div>
                  <div className="day-container" style={{ minWidth: "800px" }}>
                    <Day
                      data={tableData}
                      date={value.add(2, "day")}
                      localData={value.add(1, "day")}
                      setChange={setChange}
                      change={change}
                      statusColor={statusColor}
                      statusName={statusName}
                      setStatusName={setStatusName}
                      setStatusColor={setStatusColor}
                      dayNumber={"day2"}
                      divColor={"#e8f5e9"}
                      slotsData={slotsData}
                      callSlotsApi={callSlotsApi}
                      setCallSlotsApi={setCallSlotsApi}
                      statusData={statusData}
                    />
                  </div>
                  <div className="day-container" style={{ minWidth: "800px" }}>
                    <Day
                      data={tableData}
                      date={value.add(3, "day")}
                      localData={value.add(2, "day")}
                      setChange={setChange}
                      change={change}
                      statusColor={statusColor}
                      statusName={statusName}
                      setStatusName={setStatusName}
                      setStatusColor={setStatusColor}
                      dayNumber={"day3"}
                      divColor={"#fff3e0"}
                      slotsData={slotsData}
                      callSlotsApi={callSlotsApi}
                      setCallSlotsApi={setCallSlotsApi}
                      statusData={statusData}
                    />
                  </div>
                  <div className="day-container" style={{ minWidth: "800px" }}>
                    <Day
                      data={tableData}
                      date={value.add(4, "day")}
                      localData={value.add(3, "day")}
                      setChange={setChange}
                      change={change}
                      statusColor={statusColor}
                      statusName={statusName}
                      setStatusName={setStatusName}
                      setStatusColor={setStatusColor}
                      dayNumber={"day4"}
                      divColor={"#e1f5fe"}
                      slotsData={slotsData}
                      callSlotsApi={callSlotsApi}
                      setCallSlotsApi={setCallSlotsApi}
                      statusData={statusData}
                    />
                  </div>
                  <div className="day-container" style={{ minWidth: "800px" }}>
                    <Day
                      data={tableData}
                      date={value.add(5, "day")}
                      localData={value.add(4, "day")}
                      setChange={setChange}
                      change={change}
                      statusColor={statusColor}
                      statusName={statusName}
                      setStatusName={setStatusName}
                      setStatusColor={setStatusColor}
                      dayNumber={"day5"}
                      divColor={"#f9fbe7"}
                      slotsData={slotsData}
                      callSlotsApi={callSlotsApi}
                      setCallSlotsApi={setCallSlotsApi}
                      statusData={statusData}
                    />
                  </div>
                  <div className="day-container" style={{ minWidth: "800px" }}>
                    <Day
                      data={tableData}
                      date={value.add(6, "day")}
                      localData={value.add(5, "day")}
                      setChange={setChange}
                      change={change}
                      statusColor={statusColor}
                      statusName={statusName}
                      setStatusName={setStatusName}
                      setStatusColor={setStatusColor}
                      dayNumber={"day6"}
                      divColor={"#fce4ec"}
                      slotsData={slotsData}
                      callSlotsApi={callSlotsApi}
                      setCallSlotsApi={setCallSlotsApi}
                      statusData={statusData}
                    />
                  </div>
                  <div className="day-container" style={{ minWidth: "800px" }}>
                    <Day
                      data={tableData}
                      date={value.add(7, "day")}
                      localData={value.add(6, "day")}
                      setChange={setChange}
                      change={change}
                      statusColor={statusColor}
                      statusName={statusName}
                      setStatusName={setStatusName}
                      setStatusColor={setStatusColor}
                      dayNumber={"day7"}
                      divColor={"#f0f4c3"}
                      slotsData={slotsData}
                      callSlotsApi={callSlotsApi}
                      setCallSlotsApi={setCallSlotsApi}
                      statusData={statusData}
                    />
                  </div>
                </div>
              </div>
              <div
                className="scroll-buttons2"
                style={{ backgroundColor: "red" }}
              >
                <button
                  className="scroll-button top"
                  onClick={scrollTop}
                  onMouseDown={() => startScrolling("top")}
                  onMouseUp={stopScrolling}
                  onMouseLeave={stopScrolling}
                // style={{ fontSize: "30px" }}
                >
                  <img src={up} alt="img" />
                </button>
                <button
                  className="scroll-button bottom"
                  onClick={scrollBottom}
                  onMouseDown={() => startScrolling("bottom")}
                  onMouseUp={stopScrolling}
                  onMouseLeave={stopScrolling}
                // style={{ fontSize: "30px" }}
                >
                  <img src={down} alt="img" />
                </button>
              </div>
              <div className="scroll-buttons">
                <button
                  className="scroll-button left"
                  onClick={scrollLeft}
                  onMouseDown={() => startScrolling("left")}
                  onMouseUp={stopScrolling}
                  onMouseLeave={stopScrolling}
                >
                  <img src={left} alt="img" />
                </button>
                <button
                  className="scroll-button right "
                  onClick={scrollRight}
                  onMouseDown={() => startScrolling("right")}
                  onMouseUp={stopScrolling}
                  onMouseLeave={stopScrolling}
                >
                  <img src={right} className="scroll-img" alt="img" />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
