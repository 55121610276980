import React, { useEffect, useState } from "react";
import Navbar from "../common/Navbar";
import BackArrow from "../../assets/back-button.png";
import ThreeDot from "../../assets/three-dot.png";
import ProfileImg from "../../assets/profile-img.png";
import { Link, useNavigate } from "react-router-dom";
import AddUsers from "./AddUsers";
import EditUsers from "./EditUsers";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import axios from "axios";
import Spinner from "../common/Spinner";
const BASE_URL = process.env.REACT_APP_API_URL;

export default function DetailsRegisteredUsers() {
  const navigate = useNavigate();
  const [searchNameText, setSearchNameText] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    getUserData();
    // eslint-disable-next-line
  }, [searchNameText]);

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");
    let body = {
      agent_id: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // setIsLoading(true);
        try {
          const response = await axios.patch(
            `${BASE_URL}/auth/delete-agent`,
            body,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response?.data?.success === 1) {
            Swal.fire({
              title: "Deleted!",
              text: response?.data?.message,
              icon: "success",
              confirmButtonColor: "#336ba6",
            });
            getUserData();
          }
        } catch (error) {
          if (error?.response?.data) {
            if (
              error.response.data.message === "Session expired please login again"
            ) {
              // Handle session expiration
              localStorage.removeItem("token");
              navigate("/");
              toast.error(error?.response?.data?.message);
            } else {
              toast.error(error?.response?.data?.message);
            }
          } else {
            toast.error("Something went wrong");
          }
        }
      }
    });
  };

  const getUserData = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/auth/get-users${searchNameText ? `?name=${searchNameText}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.success === 1) {
        setUserData(response?.data?.result);
        // toast.success(response?.data?.message);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        if (
          error.response.data.message === "Session expired please login again"
        ) {
          // Handle session expiration
          localStorage.removeItem("token");
          navigate("/");
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data?.message);
        }
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchName = (e) => {
    setSearchNameText(e.target.value);
  };

  return (
    <div>
      <Navbar
        handleSearchName={handleSearchName}
        searchNameText={searchNameText}
      />
      <div className="px-3 py-3">
        <div>
          <Link to="/dashboard">
            <img alt="" src={BackArrow} />
          </Link>
        </div>
        <div className="ms-md-5 ps-md-4 pt-4 ">
          <h4 className="mb-3">Registered Users</h4>
          <AddUsers getUserData={getUserData} />
          <div className="container-fluid">
            <div className="row gap-3 justify-content-center">
              {!isLoading &&
                (userData && userData.length > 0 ? (
                  userData?.filter((item) => item.role !== "Super-Admin")?.map((item) => {
                    return (
                      <div
                        style={{ maxWidth: "250px", minWidth: "200px" }}
                        className={`col rounded p-2 ${item?.is_active ? "active" : "in-active"
                          } `}
                        key={item?._id}
                      >
                        <div className="d-flex justify-content-between ">
                          {item?.is_active ? (
                            <span className="fw-semibold">
                              <span className="dot-active"></span>Active
                            </span>
                          ) : (
                            <span className="flex-shrink-0">
                              <span className="dot-in-active"></span>In-Active
                            </span>
                          )}
                          <div className="dropdown pointer">
                            <span
                              className="fw-semibold"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {item?.role}
                              <img className="ms-2" alt="" src={ThreeDot} />
                            </span>
                            <ul className="dropdown-menu three-dot-menu">
                              <EditUsers
                                editData={item}
                                getUserData={getUserData}
                              />
                              <li
                                className="p-1 border-bottom "
                                onClick={() => {
                                  handleDelete(item?._id);
                                }}
                              >
                                Delete
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="text-center">
                          <div className="py-2 text-center w-100">
                            <img
                              className="rounded-circle"
                              style={{ width: "100px", height: "100px" }}
                              alt=""
                              src={item?.profileUrl || ProfileImg}
                            />
                          </div>
                          <div className="py-2">
                            <h6 className="mb-0" style={{ fontSize: "18px" }}>
                              {item?.name}
                            </h6>
                            <small className="break-word">{item?.email}</small>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center mt-5 fw-bold">No data found.</div>
                ))}
              {isLoading && <Spinner />}
              {/* <div
                style={{ maxWidth: "250px", minWidth: "200px" }}
                className="col rounded p-2 in-active"
              >
                <div className="d-flex justify-content-between ">
                  <span className="flex-shrink-0">
                    <span className="dot-in-active"></span>In-Active
                  </span>
                  <div className="dropdown pointer">
                    <span
                      className="fw-semibold"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Agent
                      <img className="ms-2" alt="" src={ThreeDot} />
                    </span>
                    <ul className="dropdown-menu three-dot-menu">
                      <EditUsers />
                      <li className="p-1 border-bottom " onClick={handleDelete}>
                        Delete
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="text-center">
                  <div className="py-2">
                    <img alt="" src={ProfileImg} />
                  </div>
                  <div className="py-2">
                    <h6 className="mb-0" style={{ fontSize: "18px" }}>
                      William Edson
                    </h6>
                    <small>William@gmail.com</small>
                    <small className="fw-semibold d-inline-block">
                      Last Login: <span className="fw-normal">23-02-2024</span>
                    </small>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
