import React, { useContext } from "react";
import logo from "../../assets/logo.png";
import ExportIcon from "../../assets/exportIcons.png";
import Avator from "../../assets/profile-img.png";
import DownArrow from "../../assets/downarrow.png";
import searchIcon from "../../assets/searchIcon.png";
// import toggleIcon from "../../assets/toggleIcons.png";
import profile from "../../assets/profile.png";
import changepassword from "../../assets/changepassword.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../routes/AuthProvider";
import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_API_URL;

export default function Navbar({
  handleSearch,
  searchText,
  handleSearchName,
  searchNameText,
  value,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { postLogoutApi, userRole, profileData } = useContext(AuthContext);

  const getCSVData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BASE_URL}/export-weekly-meetings/${value.format("MM-DD-YYYY")}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.success === 1) {
        const downloadUrl = response?.data?.downloadUrl;
        const link = document.createElement("a");
        link.href = downloadUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        toast.success(response?.data?.message);
      }
    } catch (error) {
      if (error?.response?.data) {
        if (
          error.response.data.message === "Session expired please login again"
        ) {
          // Handle session expiration
          localStorage.removeItem("token");
          navigate("/");
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data?.message);
        }
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      // setIsLoading(false);
    }
  };
  const handleLogout = async () => {
    await postLogoutApi(); // Wait for logout function to complete
    navigate("/"); // Navigate after successful logout
  };

  return (
    <>
      <div
        className="navbar2 justify-content-md-between justify-content-around position-sticky top-0"
        style={{ zIndex: 4000 }}
      >
        <Link to="/dashboard">
          <img src={logo} alt="" className="ps-2 order-md-1 order-1 py-2" />
        </Link>
        <div className="d-flex align-items-center justify-content-between search-parent order-md-2 order-3 flex-grow-1 mx-2 py-2">
          {location.pathname === "/dashboard" && (
            <>
              <div className="input-group px-3 py-1 bg-white rounded">
                <div className="d-flex flex-column justify-content-center bg-white p-0">
                  <span className="">
                    <img className="" alt="" src={searchIcon} />
                  </span>
                </div>

                <input
                  name="search"
                  id="password"
                  type={"text"}
                  className="form-control"
                  placeholder="Search"
                  onChange={handleSearch}
                  value={searchText || ""}
                />
              </div>
              {/* <div className="d-flex flex-column justify-content-center bg-white rounded py-2 ms-3">
                <span className="py-1 px-3">
                  <img className="" alt="" src={toggleIcon} />
                </span>
              </div> */}
            </>
          )}
          {location.pathname === "/registered-users" && (
            <>
              <div className="input-group px-3 py-1 bg-white rounded">
                <div className="d-flex flex-column justify-content-center bg-white p-0">
                  <span className="">
                    <img className="" alt="" src={searchIcon} />
                  </span>
                </div>

                <input
                  name="search"
                  id="password"
                  type={"text"}
                  className="form-control"
                  placeholder="Search"
                  onChange={handleSearchName}
                  value={searchNameText || ""}
                />
              </div>
              {/* <div className="d-flex flex-column justify-content-center bg-white rounded py-2 ms-3">
                <span className="py-1 px-3">
                  <img className="" alt="" src={toggleIcon} />
                </span>
              </div> */}
            </>
          )}
        </div>
        <div className="order-md-3 order-2 py-2 position-relative">
          {location.pathname === "/dashboard" && (
            <span
              className="text-white export rounded-2 me-4 pointer"
              onClick={getCSVData}
            >
              <img src={ExportIcon} alt="" className="pe-2" />
              Export
            </span>
          )}
          <span
            className="text-white rounded-2 py-3 pointer"
            id="Button2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src={profileData?.profileUrl || Avator}
              alt=""
              className="avator rounded-circle flex-shrink-0"
            />
            <img src={DownArrow} alt="" className="px-2 flex-shrink-0" />
          </span>
          <div
            className="position-absolute logout-widget dropdown-menu"
            aria-labelledby="Button2"
          >
            <ul className="list-unstyled mb-1">
              <li>
                <Link
                  to="/my-profile"
                  className="text-decoration-none logout-widget-item py-2 px-3 border-bottom border-gray w-100"
                >
                  <span className="pe-2">
                    <img alt="" src={profile} />
                  </span>
                  My Profile
                </Link>
              </li>
              {(userRole === "Admin" || userRole === "Super-Admin") && (
                <>
                  <li>
                    <Link
                      to="/registered-users"
                      className="text-decoration-none logout-widget-item py-2 px-3 border-bottom border-gray w-100"
                    >
                      <span className="pe-2">
                        <img alt="" src={profile} />
                      </span>
                      Manage Users
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/status"
                      className="text-decoration-none logout-widget-item py-2 px-3 border-bottom border-gray w-100"
                    >
                      <span className="pe-2">
                        <img alt="" src={profile} />
                      </span>
                      Manage Status
                    </Link>
                  </li>
                </>
              )}
              {/* <li
                  onClick={handleHide}
                  value="3"
                  className="py-2 px-3 border-bottom border-gray"
                >
                  <span className="pe-2">
                    <img alt="" src={changepassword} />
                  </span>
                  Change Password
                </li> */}
              <li>
                <Link
                  to="/graph"
                  className="text-decoration-none logout-widget-item py-2 px-3 border-bottom border-gray w-100"
                >
                  <span className="pe-2">
                    <img alt="" src={profile} />
                  </span>
                  Statistics
                </Link>
              </li>
              <li
                onClick={handleLogout}
                className="text-decoration-none logout-widget-item py-2 px-3 border-bottom border-gray w-100"
              >
                <span className="pe-2">
                  <img alt="" src={changepassword} />
                </span>
                Logout
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
